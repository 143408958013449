<template>
  <div class="space-y-8">
    <div class="tw-section">
      <div>
        <h2>투자 교육용 게임</h2>
        <div class="pl-4 space-y-4">
          <div>
            <img class="rounded-lg w-full" src="/img/iss/iss_logo.png" />
          </div>
          <div>
            <a
              class="tw-btn"
              href="https://serverdown.tistory.com/546"
              target="_blank"
            >
              PLAY
            </a>
          </div>
          <div>- 준비중입니다.<br /></div>
        </div>
      </div>
    </div>

    <div class="tw-section">
      <div>
        <h2>무한 슈팅 게임</h2>
        <div class="pl-4 space-y-4">
          <div>
            <img class="rounded-lg w-full" src="/img/iss/iss_logo.png" />
          </div>
          <div>
            <a
              class="tw-btn"
              href="https://serverdown.tistory.com/485"
              target="_blank"
            >
              PLAY
            </a>
          </div>
          <div>
            - 유니티로 제작되었습니다.<br />
            - 데스크톱의 웹브라우져에서 플레이 가능합니다.<br />
            - v0.2.4.1 (마지막 업데이트 2022-07-03)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("sid/projects mounted");
    this.$store.commitRoot("set_ui", this.$route.fullPath);
  },
};
</script>

<style scoped>
.tw-section {
  @apply bg-gray-300 dark:bg-gray-600 dark:text-white shadow rounded-lg px-4 pb-8;
}
</style>
